import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./ImageCarouselStyle";
import Button from "../Buttons/Buttons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { animated } from "react-spring";

import "./ken-burns.css";

const ImageCarousel = () => {
  const classes = useStyles();

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box className={classes.CarouselContainer}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop
        autoPlay
        onChange={(index) => setActiveIndex(index)}
        showArrows={false}
        renderIndicator={(clickHandler, isSelected, index) => (
          <Box
            onClick={clickHandler}
            className={classes.controlDot}
            style={{
              display: "inline-block",
              width: isSelected ? "64px" : "20px",
              height: "8px",
              borderRadius: 8,
              backgroundColor: isSelected
                ? "#FFF"
                : "rgba(255, 255, 255, 0.21)",
              margin: "0 5px",
              cursor: "pointer",
            }}
          ></Box>
        )}
        className={classes.Carousel}
      >
        <Box>
          <div className={activeIndex === 0 ? "ken-burns" : ""}>
            <img
              src="Gallerie-photo.jpg"
              height="850px"
              alt=""
              className={`${activeIndex === 0 ? "fade-in" : "fade-out"} ${
                classes.SlideImage
              }`}
            />
          </div>
        </Box>
        <Box>
          <div className={activeIndex === 1 ? "ken-burns" : ""}>
            <img
              src="iStock-980930294.jpg"
              height="850px"
              alt=""
              className={`${activeIndex === 1 ? "fade-in" : "fade-out"} ${
                classes.SlideImage
              }`}
            />
          </div>
        </Box>
        <Box>
          <div className={activeIndex === 2 ? "ken-burns" : ""}>
            <img
              src="iStock-1325142822.jpg"
              height="850px"
              alt=""
              className={`${activeIndex === 2 ? "fade-in" : "fade-out"} ${
                classes.SlideImage
              }`}
            />
          </div>
        </Box>
      </Carousel>

      <Box className={classes.StaticTextWrpper}>
        <animated.div className={classes.TextContainer}>
          {activeIndex === 0 && (
            <Typography className={classes.StaticText}>
              <div data-aos="fade-right" data-aos-delay="300">
                <span className={classes.IndustrialText}>INDUSTRIAL</span>{" "}
                <span className={classes.DuText}>DIGITAL</span>{" "}
                <span className={classes.TwinText}>TWINS,</span>
              </div>
              <div data-aos="fade-left" data-aos-delay="300">
                <span className={classes.MiddlineLineSpan}>for</span>
              </div>
              <div data-aos="fade-right" data-aos-delay="300">
                <span className={classes.PerformanceText}>PERFORMANCE</span>
              </div>
            </Typography>
          )}

          {activeIndex === 1 && (
            <Typography className={classes.StaticText}>
              <div data-aos="fade-right" data-aos-delay="300">
                <span className={classes.alwaysText}>ALWAYS</span>{" "}
                <span className={classes.DuText}>ON</span>{" "}
                <span className={classes.BimText}>TRACK,</span>
              </div>
              <div data-aos="fade-left" data-aos-delay="300">
                <span className={classes.travelBimText}>TRAVELBIM,</span>
              </div>
              <div data-aos="fade-right" data-aos-delay="300">
                <span className={classes.PerformanceText}>
                  Votre Expertise Ferroviaire
                </span>
              </div>
            </Typography>
          )}
          {activeIndex === 2 && (
            <Typography className={classes.StaticText}>
              <div data-aos="fade-right" data-aos-delay="300">
                <span className={classes.AlchemisteText}>ALCHIMISTE</span>{" "}
                <span className={classes.DuText}> du</span>
                <span className={classes.BimText}> BIM,</span>
              </div>
              <div data-aos="fade-left" data-aos-delay="300">
                <span className={classes.MiddlineLineSpan}>
                  transformons vos données
                </span>
              </div>
              <div data-aos="fade-right" data-aos-delay="300">
                <span className={classes.EnText}>en</span>{" "}
                <span className={classes.PerformanceText}>PERFORMANCE</span>
              </div>
            </Typography>
          )}
          <div data-aos="fade-up" data-aos-delay="300">
            <AnchorLink
              href="#contactez-nous"
              key="contactez-nous"
              offset="140"
              className={classes.ContactUsButton}
            >
              <Button
                Title="Contactez-nous"
                className={classes.ContactUsButton}
              />
            </AnchorLink>
          </div>
        </animated.div>
      </Box>
    </Box>
  );
};

export default ImageCarousel;
