import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  HeaderMainContainer: {
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 2,
    backgroundColor: "#1F3052",
    height: 90,
    padding: "22px 95px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "22px 32px",
    "@media (max-width: 1440px)": {
      padding: "22px 32px",
    },

    "@media (max-width: 920px)": {
      padding: "18px 32px",
      height: 60,
    },

    "@media (max-width: 480px)": {
      padding: "18px 32px",
      height: 40,
    },
  },

  WebsiteLogo: {
    cursor: "pointer",
    width: 260,
    "@media (max-width: 920px)": {
      width: 100,
    },
    "@media (max-width: 480px)": {
      width: 80,
    },
  },
  ListWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: 25,
  },

  ListItemText: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: 16,
      fontWeight: 600,
      cursor: "pointer",
      margin: "0px 15px",
    },

    "@media (max-width: 1100px)": {
      display: "none",
    },
  },

  NavContactUsButtons: {
    textDecoration: "none",
    "@media (max-width: 1100px)": {
      display: "none",
    },
  },

  ResponsiveNavbar: {
    display: "none",
    marginRight: "1px",
    marginLeft: "5px",
    "@media (max-width: 1100px)": {
      display: "block",
    },
  },

  ContactUsButton: {
    "&.MuiButtonBase-root": {
      width: 150,
      height: 51,
    },
  },
  HeaderSubWrapper: {
    marginRight: "50px",
    display: "flex",
    alignItems: "center",

    "@media (max-width: 1100px)": {
      margin: "77px",
    },
  },
  NavLinks: {
    color: "#FFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#49A995",
    },
  },

  ResNavLinks: {
    color: "#FFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#49A995",
    },
  },

  ResponsiveListItemText: {
    width: 300,
    display: "flex",
    flexDirection: "column",
  },

  NavbarList: {
    display: "flex",
    flexDirection: "column",
    gap: "27px",
    paddingLeft: "49px",
    paddingTop: "76px",
    backgroundColor: "#1F3052",
    height: "100vh",
  },

  NavCloseBtn: {
    position: "absolute",
    top: "14px",
    right: "15px",
    color: "#FFF",
    fontSize: "35px !important",

    "&:hover": {
      color: "#49A995",
    },
  },

  Menu: {
    fontSize: "45px !important",
    marginBottom: "9px",
    marginRight: "-30px",
    cursor: "pointer",
    color: "#FFF",
    zIndex: 99999,
  },
  ResposiveContactUsButtons: {
    textDecoration: "none",
  },
  Drawer: {
    "& .MuiPaper-root": {
      zIndex: 99999,
    },
  },
}));
