import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  CarouselContainer: {
    position: "relative",
    marginTop: "8.3rem",

    "@media (max-width: 1443px)": {
      marginTop: "8.3rem",
    },

    "@media (max-width: 921px)": {
      marginTop: "6rem",
    },

    "@media (max-width: 581px)": {
      marginTop: "5rem",
    },

    "@media (max-width: 480px)": {
      marginTop: "4rem",
    },
  },
  SlideImage: {
    filter: "brightness(0.5) !important",
    objectFit: "cover",
    width: "100%",
  },
  Carousel: {
    "& .control-dots": {
      display: "flex",
      justifyContent: "flex-end",
      margin: "59px -121px !important",
    },
  },

  StaticText: {
    "&.MuiTypography-root": {
      textAlign: "center",
      color: "#FFF",
      fontSize: "75px",
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "italic",
      fontWeight: "400",
      lineHeight: 1.1,
      letterSpacing: "-1.5px",

      "@media (max-width: 745px)": {
        fontSize: "70px",
      },
      "@media (max-width: 665px)": {
        fontSize: "56px",
      },
      "@media (max-width: 575px)": {
        fontSize: "50px",
      },

      "@media (max-width: 535px)": {
        fontSize: "45px",
      },

      "@media (max-width: 450px)": {
        fontSize: "40px",
      },
    },
  },
  // BimText: {
  //   display: "block",
  //   fontWeight: 700,
  //   fontFamily: "'Poppins', sans-serif",
  //   fontStyle: "normal",
  //   marginLeft: "14rem",
  // },
  AlchemisteText: {
    color: "#91E6D4",
    fontSize: "80px",
    fontWeight: 275,
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    textTransform: "uppercase",

    "@media (max-width: 900px)": {
      fontSize: "100px",
    },

    "@media (max-width: 750px)": {
      fontSize: "77px",
    },

    "@media (max-width: 550px)": {
      fontSize: "67px",
    },

    "@media (max-width: 550px)": {
      fontSize: "60px",
    },
  },
  alwaysText: {
    color: "#91E6D4",
    fontSize: "96px",
    fontWeight: 700,
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    textTransform: "uppercase",

    "@media (max-width: 900px)": {
      fontSize: "100px",
    },

    "@media (max-width: 750px)": {
      fontSize: "77px",
    },

    "@media (max-width: 550px)": {
      fontSize: "67px",
    },

    "@media (max-width: 550px)": {
      fontSize: "60px",
    },
  },
  BimText: {
    color: "#91E6D4",
    fontSize: "96px",
    fontWeight: 700,
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    textTransform: "uppercase",

    "@media (max-width: 900px)": {
      fontSize: "100px",
    },

    "@media (max-width: 750px)": {
      fontSize: "77px",
    },

    "@media (max-width: 550px)": {
      fontSize: "67px",
    },

    "@media (max-width: 550px)": {
      fontSize: "60px",
    },
  },
  travelBimText: {
    fontWeight: 700,
    fontStyle: "normal",
  },
  TwinText: {
    color: "#91E6D4",
    fontSize: 80,
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    textTransform: "uppercase",

    "@media (max-width: 900px)": {
      fontSize: "100px",
    },

    "@media (max-width: 750px)": {
      fontSize: "77px",
    },

    "@media (max-width: 550px)": {
      fontSize: "67px",
    },

    "@media (max-width: 550px)": {
      fontSize: "60px",
    },
  },

  DuText: {
    color: "#91E6D4",
    fontSize: "75px",
    fontStyle: "italic",
    fontWeight: 400,
    textTransform: "lowercase",

    "@media (max-width: 750px)": {
      fontSize: "70px",
    },
    "@media (max-width: 550px)": {
      fontSize: "60px",
    },

    "@media (max-width: 440px)": {
      fontSize: "50px",
    },
  },
  MiddleLineSpan: {
    display: "block",
    fontSize: "64px",
    fontStyle: "italic",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    textTransform: "lowercase",
    "@media (max-width: 900px)": {
      fontSize: "64px",
    },

    "@media (max-width: 750px)": {
      fontSize: "64px",
    },

    "@media (max-width: 665px)": {
      fontSize: "64px",
    },

    "@media (max-width: 580px)": {
      fontSize: "64px",
    },

    "@media (max-width: 515px)": {
      fontSize: "64px",
    },

    "@media (max-width: 440px)": {
      fontSize: "54px",
    },
  },
  EnText: {
    fontSize: "75px",
    fontStyle: "normal",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 300,
    "@media (max-width: 900px)": {
      fontSize: "75px",
    },

    "@media (max-width: 750px)": {
      fontSize: "75px",
    },

    "@media (max-width: 665px)": {
      fontSize: "75px",
    },

    "@media (max-width: 580px)": {
      fontSize: "75px",
    },

    "@media (max-width: 515px)": {
      fontSize: "65px",
    },

    "@media (max-width: 440px)": {
      fontSize: "55px",
    },
  },
  PerformanceText: {
    fontSize: "80px",
    fontStyle: "normal",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    "@media (max-width: 900px)": {
      fontSize: "80px",
    },

    "@media (max-width: 750px)": {
      fontSize: "80px",
    },

    "@media (max-width: 665px)": {
      fontSize: "80px",
    },

    "@media (max-width: 580px)": {
      fontSize: "70px",
    },

    "@media (max-width: 515px)": {
      fontSize: "60px",
    },

    "@media (max-width: 440px)": {
      fontSize: "50px",
    },
  },
  IndustrialText: {
    fontSize: "80px",
    fontStyle: "normal",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    color: "#91E6D4",
    "@media (max-width: 900px)": {
      fontSize: "80px",
    },

    "@media (max-width: 750px)": {
      fontSize: "80px",
    },

    "@media (max-width: 665px)": {
      fontSize: "80px",
    },

    "@media (max-width: 580px)": {
      fontSize: "70px",
    },

    "@media (max-width: 515px)": {
      fontSize: "60px",
    },

    "@media (max-width: 440px)": {
      fontSize: "50px",
    },
  },
  StaticTextWrpper: {
    position: "absolute",
    top: 190,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  ContactUsButton: {
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    "&.MuiButtonBase-root": {
      width: 187,
      height: 51,
      marginTop: "2rem",
    },
  },
}));
